export default [
  {
    path: '/reports',
    name: 'view-all-reports',
    component: () => import('@/views/apps/pages/reports')
  },
  {
    path: '/exports',
    name: 'export-reports',
    component: () => import('@/views/apps/pages/reportExports.vue')
  },
  {
    path: '/reports/accrual',
    name: 'parking-redemption-report',
    component: () => import('@/views/apps/pages/accrualReport.vue')
  },
  {
    path: '/reports/redemption',
    name: 'redemption-report',
    component: () => import('@/views/apps/pages/redemptionReport.vue')
  },
  {
    path: '/reports/voucherUsageReport',
    name: 'redemption-report',
    component: () => import('@/views/apps/pages/voucherUsageReport.vue')
  },
  {
    path: '/reports/parkingRedemption',
    name: 'parking-redemption-report',
    component: () => import('@/views/apps/pages/ParkingRedemptionReport')
  },
  {
    path: '/reports/reversedTransaction',
    name: 'reversed-transactions-report',
    component: () => import('@/views/apps/pages/reversedTransactionsReport')
  },
  {
    path: '/reports/pendingTransaction',
    name: 'pending-transactions-report',
    component: () => import('@/views/apps/pages/pendingTransactionsReport')
  },
  {
    path: '/reports/customers',
    name: 'customers-report',
    component: () => import('@/views/apps/pages/customersReport')
  },
  {
    path: '/reports/users',
    name: 'users-report',
    component: () => import('@/views/apps/pages/usersReport')
  },
  {
    path: '/reports/newRegistration',
    name: 'new-registrations-report',
    component: () => import('@/views/apps/pages/newRegistrationsReport.vue')
  },
  {
    path: '/reports/bonusPointsReport',
    name: 'bonus-points-report',
    component: () => import('@/views/apps/pages/bonusPointsReport.vue')
  }

]
