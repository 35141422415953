import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {

      addPartner({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.addPartner(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()


                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },
      saveTierSettings({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.saveTierSettings(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },
      getTierSettings({commit}) {
          return new Promise((resolve,reject) => {
              httpService.getTierSettings()
                  .then(response => {
                      resolve(response.data);

                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      upgradeCustomerTier({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.upgradeCustomerTier(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },
      runCustomerTierExpiry({commit}) {
          return new Promise((resolve,reject) => {

              httpService.runCustomerTierExpiry()
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },

      getPartnerTransactions({commit}) {
          return new Promise((resolve,reject) => {
              httpService.getPartnerTransactions()
                  .then(response => {
                      resolve(response.data);

                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

      editPartner({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.editPartner(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()


                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },

      deletePartner({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.deletePartner(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()


                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },


      getLoyaltySettings({commit}){

      return new Promise((resolve,reject) => {
        httpService.getLoyaltySettings()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },
    getLotterySettings({commit}){

      return new Promise((resolve,reject) => {
        httpService.getLotterySettings()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      getPartners() {
          return new Promise((resolve,reject) => {
              httpService.getPartners()
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },


    updateLoyaltySettings({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.updateLoyaltySettings(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },

    saveNotice({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.saveNotice(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    savePromotion({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.savePromotion(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    editNotice({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.editNotice(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()
            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    deletePromotion({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.deletePromotion(data.promotionId)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    deleteNotice({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.deleteNotice(data.noticeBoardId)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },

    getPromotions({commit}){

      return new Promise((resolve,reject) => {
        httpService.getPromotions()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },

    getNotices({commit}){

      return new Promise((resolve,reject) => {
        httpService.getNotices()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },

    updateLotterySettings({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.updateLotterySettings(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    saveGeneralSettings({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.saveGeneralSettings(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    saveTax({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.saveTax(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    deleteTax({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.deleteTax(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    getTaxes({commit}){

      return new Promise((resolve,reject) => {
        httpService.getTaxes()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },
    getGeneralSettings({commit}){

      return new Promise((resolve,reject) => {
        httpService.getGeneralSettings()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },

    saveSMTPSettings({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.saveSMTPSettings(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    getSMTPSettings({commit}){

      return new Promise((resolve,reject) => {
        httpService.getSMTPSettings()
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },
    getMerchantLoyaltySettings({commit}){
      //getMerchantLoyaltySettings



      return new Promise((resolve,reject) => {
        httpService.getMerchantLoyaltySettings()
          .then(response => {


            resolve(response.data);
          })
          .catch(error => {
            console.log(error)

            //reject({message: axios.defaults.headers})
          })
      });

    },

    saveOutletConversion({commit}, payload){
      return new Promise((resolve,reject) => {
        httpService.saveOutletConversion(payload)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

            }
            else{
              reject({message: response.data.message})
            }
          })
          .catch(error => {
            console.log(error)

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getOutletConversion({commit}, payload){
      return new Promise((resolve,reject) => {
        httpService.getOutletConversion(payload.id)
          .then(response => {

            resolve(response.data);
          })
          .catch(error => {
            console.log(error)

            //reject({message: axios.defaults.headers})
          })
      });

    },

    addRole({commit}, roleData){
      return new Promise((resolve,reject) => {

        httpService.addRole(roleData)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },
    updateRole({commit}, roleData){
      return new Promise((resolve,reject) => {

        httpService.updateRole(roleData)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()
            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

          })
      })
    },

    getRoles({commit}){

      return new Promise((resolve,reject) => {
        httpService.getRoles()
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getRoleById({commit}, roleData){
      return new Promise((resolve,reject) => {
        httpService.getRoleById(roleData.id)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },

      getPaymentModes({commit}){
          return new Promise((resolve,reject) => {
              httpService.getPaymentModes()
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      saveReportRecipientsSettings({commit}, reportSettingsData){
          return new Promise((resolve,reject) => {

              httpService.saveReportRecipientsSettings(reportSettingsData)
                  .then(response => {
                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => { reject(error)

                  })
          })
      },
      editReportRecipientsSettings({commit}, reportSettingsData){
          return new Promise((resolve,reject) => {

              httpService.editReportRecipientsSettings(reportSettingsData)
                  .then(response => {
                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => { reject(error)

                  })
          })
      },
      getReportRecipientSettings({commit}){
          return new Promise((resolve,reject) => {
              httpService.getReportRecipientSettings()
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

      deleteReportRecipientSettings({commit}, reportSettingsData){
          return new Promise((resolve,reject) => {

              httpService.deleteReportRecipientSettings(reportSettingsData.id)
                  .then(response => {
                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                  })
          })
      },
      triggerMonthlyReports({commit}) {
          return new Promise((resolve,reject) => {

              httpService.triggerMonthlyReports()
                  .then(response => {
                      if(response.status == 200) {
                          resolve()
                      } else {
                          reject({message: "Server error, please try again later"})
                      }
                  })
                  .catch(error => { reject(error)

                  })
          })
      }
  }
};
